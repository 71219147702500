/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import { Card } from '@themesberg/react-bootstrap';
import * as turf from '@turf/turf';

mapboxgl.accessToken =
  'pk.eyJ1IjoidG9iaXQiLCJhIjoiY2t2ODAxOXh6MXIxeDJ2bzB6eDc2eTVqaCJ9.7G78rRsslobB3HJutQ84Iw';

function Map() {
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [lng, setLng] = React.useState(39.657325);
  const [lat, setLat] = React.useState(-4.024902);
  const [zoom, setZoom] = React.useState(9);

  React.useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v10',
      center: [lng, lat],
      zoom: zoom,
    });
  }, [lat, lng, zoom]);

  React.useEffect(() => {
    if (map.current) return;
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    // map.current.on('load', () => {
    //   map.current.addSource('theRoute', {
    //     type: 'geojson',
    //     data: {
    //       type: 'Feature',
    //     },
    //   });

    //   map.current.addLayer({
    //     id: 'theRoute',
    //     type: 'line',
    //     source: 'theRoute',
    //     layout: {
    //       'line-join': 'round',
    //       'line-cap': 'round',
    //     },
    //     paint: {
    //       'line-color': '#cccccc',
    //       'line-opacity': 0.5,
    //       'line-width': 13,
    //       'line-blur': 0.5,
    //     },
    //   });

    //   // Source and layer for the bounding box
    //   map.current.addSource('theBox', {
    //     type: 'geojson',
    //     data: {
    //       type: 'Feature',
    //     },
    //   });
    //   map.current.addLayer({
    //     id: 'theBox',
    //     type: 'fill',
    //     source: 'theBox',
    //     layout: {},
    //     paint: {
    //       'fill-color': '#FFC300',
    //       'fill-opacity': 0.5,
    //       'fill-outline-color': '#FFC300',
    //     },
    //   });

    //   map.current.addLayer({
    //     id: 'clearances',
    //     type: 'fill',
    //     source: {
    //       type: 'geojson',
    //       data: obstacle,
    //     },
    //     layout: {},
    //     paint: {
    //       'fill-color': '#f03b20',
    //       'fill-opacity': 0.5,
    //       'fill-outline-color': '#f03b20',
    //     },
    //   });
    // });
    // map.current.addControl(directions, 'top-right');
    map.current.scrollZoom.enable();
  }, []);

  const directions = new MapboxDirections({
    // accessToken: mapboxgl.accessToken,
    unit: 'metric',
    profile: 'mapbox/driving',
    alternatives: false,
    geometries: 'geojson',
    controls: { instructions: false },
    flyTo: false,
  });

  const clearances = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-3.221228, 30.114498],
        },
        properties: {
          clearance: "13' 2",
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-3.396389, 39.954164],
        },
        properties: {
          clearance: "12' 0",
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-3.221228, 40.129098],
        },
        properties: {
          clearance: "13' 2",
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [-3.201228, 40.114498],
        },
        properties: {
          clearance: "13' 2",
        },
      },
    ],
  };

  const obstacle = turf.buffer(clearances, 0.25, { units: 'kilometers' });
  const bbox = [0, 0, 0, 0];
  const polygon = turf.bboxPolygon(bbox);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Map</Card.Title>
        <Card.Body>
          <div ref={mapContainer} className="map-container" />
        </Card.Body>
      </Card.Body>
    </Card>
  );
}

export default React.memo(Map);
