import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Footer, Navbar, Preloader, Sidebar } from '../../components';

type Props = {
  component?: any;
  exact?: boolean;
  path?: string;
};

const RouteWithSidebar: React.FC<Props> = (props) => {
  const [loaded, setLoaded] = React.useState(false);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [showSettings, setShowSettings] = React.useState(
    localStorageIsSettingsVisible
  );

  const { path, component: Component, ...rest } = props;

  // React.useEffect(() => {
  //   const timer = setTimeout(() => setLoaded(true), 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    // localStorage.setItem('settingsVisible', !showSettings);
  };

  return (
    // <Routes>
    //   <Route
    //     path={path}
    //     {...rest}
    //     element={
    <div style={{ display: 'flex' }}>
      {/* <Preloader show={loaded ? false : true} /> */}
      <Sidebar />

      <main style={{ width: '100%' }} className="content">
        <Navbar />
        <Outlet />
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </div>
    //     }
    //   />
    // </Routes>
  );
};

export default RouteWithSidebar;
