import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import React from 'react';

function TrackAndTrace() {
  const [val, setVal] = React.useState({});
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVal({ [name]: value });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Track and Trace</Card.Title>
      </Card.Header>
      <Card.Body>
        <h6 className="mb-2">Track your shipment</h6>
        <Form>
          <Form.Group>
            <Row>
              <Col className="form-check">
                {' '}
                <Form.Check
                  type="radio"
                  checked
                  value="shipmentNumber"
                  name="shipmentNumber"
                  id="track-and-traceShipmentNumber"
                  onChange={handleChange}
                />
                <Form.Label
                  className="form-check-label"
                  htmlFor="track-and-traceShipmentNumber"
                >
                  Shipment number
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col className="form-check">
                <Form.Check
                  type="radio"
                  value="customerReference"
                  name="customerReference"
                  id="track-and-traceCustomerReference"
                  onChange={handleChange}
                />
                <Form.Label
                  className="form-check-label"
                  htmlFor="track-and-traceCustomerReference"
                >
                  Customer reference
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                <Form.Control
                  type="text"
                  placeholder="Enter Shipment number / Customer reference"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={3}>
                <Button variant="secondary" size="lg">
                  Search
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default TrackAndTrace;
