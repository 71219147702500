/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faExternalLinkAlt,
  faTimesCircle,
  faCheckCircle,
  faCalendarAlt,
  faCodeBranch,
  faShoppingCart,
  faFolder,
  faMapMarkedAlt,
  faPager,
  faFileCode,
  faDownload,
  faFire,
  faCaravan,
  faMap,
  faShip,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBootstrap,
  faGithub,
  faJs,
  faReact,
  faSass,
} from '@fortawesome/free-brands-svg-icons';
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Form,
  Navbar,
  Nav,
  Badge,
} from '@themesberg/react-bootstrap';
import { Link,  Navigate   } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Code from '../components/CodeEditor';
import GitHubButton from 'react-github-btn';

import { Routes } from '../../routes/routes';
import TotamicaLogo from '../../assets/img/themesberg-logo.svg';
import MockupPresentation from '../../assets/img/v-mockup.png';
import ReactHero from '../../assets/img/technologies/react-hero-logo.svg';
import MapboxImg from '../../assets/img/mockup-map-presentation.png';
import CalendarImg from '../../assets/img/mockup-calendar-presentation.png';
import TotamicaImage from '../../assets/img/totamicalogo1.png';
import BS5IllustrationsImg from '../../assets/img/illustrations/bs5-illustrations.svg';
import BS5Logo from '../../assets/img/technologies/bootstrap-5-logo.svg';
import ReactLogo from '../../assets/img/technologies/react-logo.svg';

import shippers from '../data/pages';
import features from '../data/features';
import howItWorks from '../data/how.it.works';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';

export default () => {
  const PagePreview = (props: any) => {
    const { name, image, link } = props;

    return (
      <Col xs={3} className="mb-5">
        <Card.Link
          as={Link}
          to={link}
          className="page-preview page-preview-lg scale-up-hover-2"
        >
          <Image
            src={image}
            className="shadow-lg rounded scale"
            alt="Dashboard page preview"
            rounded={true}
          />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name}{' '}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props: any) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props: any) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : '';

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip id={''}>{tooltip}</Tooltip>}
      >
        <li
          data-toggle="tooltip"
          data-placement="left"
          title="Main folder that you will be working with"
        >
          <FontAwesomeIcon
            icon={icon ? icon : faFolder}
            className={`${color} me-2`}
          />{' '}
          {name}
        </li>
      </OverlayTrigger>
    );
  };

  const Steps = () => {
    return (
      <div className="steps">
        <div className="steps-header">
          <h4 className="steps-title">How it works</h4>
        </div>
        <div className="steps-content">
          <div className="steps-content-item">
            <div className="steps-content-item-icon">
              <FontAwesomeIcon icon={faMapMarkedAlt} />
            </div>
            <div className="steps-content-item-content">
              <h5 className="steps-content-item-title">
                <span className="steps-content-item-title-icon">
                  <FontAwesomeIcon icon={faMap} />
                </span>
                <span>Select your location</span>
              </h5>
              <p className="steps-content-item-description">
                Select your location and see the available services
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [checked, setChecked] = React.useState(false);
  const [value, setValue] = React.useState('344545HQ');
  const [trackingID, setTrackingID] = React.useState('');
  const handleChange = () => {
    console.log('handleChange');
  };

  const handleIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingID(e.target.value);
  };

  const [data, setData] = React.useState({
    username: '',
    password: '',
  });
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (data.username === 'tobit' && data.password === 'totamica') {
      return <Navigate to={'/dashboard/overview'} />;
    } else {
      return <Navigate to={'/'} />;
    }
  };
  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <FontAwesomeIcon icon={faFire} className="logo" />
            <span className="ms-2 brand-text d-none d-md-inline">
              Totamica Logistics
            </span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#totamica">
                  Totamica
                </Nav.Link>
                {/* <Nav.Link as={HashLink} to="#shippers">
                  Shippers
                </Nav.Link> */}
                <Nav.Link as={HashLink} to="#how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={HashLink} to="#track-shipment">
                  Track shipment
                </Nav.Link>
                <Nav.Link as={HashLink} to="#contact">
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Button
              variant="outline-white"
              className="ms-3"
              as={HashLink}
              to={Routes.Signin.path}
            >
              Login
            </Button>
          </div>
        </Container>
      </Navbar>
      <section
        className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white"
        id="home"
      >
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fa fa-fire"></span>
              </div>
              <h1 className="fw-bolder text-secondary">
                Welcome to Totamica Logistics
              </h1>
              <p className="text-muted fw-light mb-5 h5">
                @ Totamica we are designed to respond and build to deliver
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  variant="secondary"
                  as={Link}
                  to={Routes.DashboardOverview.path}
                  className="text-dark me-3"
                >
                  Explore more
                </Button>
              </div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  <a
                    href="https://tobitmisoi.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFire} className="logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg
              className="fill-soft"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3000 85.4"
            >
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>
      <div className="section pt-0">
        <Container className="mt-n10 mt-lg-n12 z-2">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image src={MockupPresentation} alt="Mockup presentation" />
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mt-lg-6">
            <Col xs={6} md={3} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faBuilding} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">62</h3>
              <p className="text-gray">Offices</p>
            </Col>
            <Col xs={6} md={3} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faMap} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">9</h3>
              <p className="text-gray">Countries</p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faCaravan} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">1,300+</h3>
              <p className="text-gray">Carriers</p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon
                  color="secondary"
                  icon={faShip}
                  className="text-secondary"
                />
              </div>
              <h3 className="fw-bolder">12,000+</h3>
              <p className="text-gray">Shippers</p>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="section section-md bg-soft pt-lg-3" id="totamica">
        <Container>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2>Totamica</h2>
              <p className="mb-3 lead fw-bold">
                A safety first transportation solution provider. This is
                Totamica
              </p>
            </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={TotamicaImage} alt="Calendar Preview" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={12}>
              <h2>Why Totamica</h2>
              <p className="mb-4">
                Totamica logistics is the largest asset-based logistics provider
                in Kenya, utilizing its fleet of over 1,000 trucks and over 700
                trailers to provide road transportation solutions for its
                customers across the country. With a wide-reaching network
                throughout the country, the company manages to complete more
                than 80,000 full truck load (FTL) deliveries annually, while
                sustaining a high quality of the services offered.
              </p>
              <p className="mb-4">
                The company’s fleet of trucks and trailers is supported by more
                than 8,000 professionals, including 5,000 truck drivers, to make
                sure that your full loads arrive safely and on time. Utilising
                the newest technologies in the industry, alongside with LEAN
                management methodology, Totamica Logistics also aims to offer an
                end-to-end solution that puts an emphasis on efficiency, making
                the communication process much smoother. Furthermore, with the
                road transportation company‘s digital solutions, you can be
                certain that your supply chain will be taken care of with the
                utmost responsibility, transparency, and efficiency.
              </p>
              <p className="mb-4">
                With over 15 years of experience in the road transport industry,
                Totamica Logistics is an experienced operator that is able to
                provide its customers with a tailored solution that will cater
                to their needs, whether that means carrying fresh &amp; frozen
                food or high-value cargo.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2 className="d-flex align-items-center">Map</h2>
              <p className="mb-3 lead fw-bold">
                We do cover a wide range of location within the 9 countries.
              </p>
              <p className="mb-4">
                We are looking forward to advance our networks and achieve a
                wider range for our clients
              </p>
            </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={MapboxImg} alt="MapBox" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h2 className="d-flex align-items-center">Calendar </h2>
              <p className="mb-3 lead fw-bold">
                At totamica scheduling a shipment is our secret
              </p>
              <p className="mb-4">
                You can schedule a shipment from anywhere you are just by
                clicking and selecting your delivery period. Just add, edit and
                submit. EZ-PZ
              </p>
              <Button
                href="https://demo.tobitmisoi.com/totam-pro-react/#/calendar"
                className="me-3"
                variant="secondary"
                target="_blank"
              >
                <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />{' '}
                Schedule Shipment
              </Button>
            </Col>
            <Col lg={6}>
              <Image src={CalendarImg} alt="Calendar Preview" />
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="section section-sm pt-0" id="shippers">
        <Container>
          <Row className="justify-content-center mb-5 mb-lg-6">
            <Col xs={12} className="text-center">
              <h2 className="px-lg-5">Shippers</h2>
              <p className="lead px-lg-10">
                With our many shippers you are able to choose one of your best
                shippers according to there ratings
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            <div style={{ padding: 30 }}>
              <Paper>
                <Grid
                  container
                  spacing={3}
                  direction={'column'}
                  justify={'center'}
                  alignItems={'center'}
                >
                  <Grid item xs={12}>
                    <TextField
                      label="Username"
                      onChange={(e) =>
                        setData({ ...data, username: e.target.value })
                      }
                      value={data.username}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      value={data.password}
                      type={'password'}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Keep me logged in"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      className="btn mb-2 mr-2 btn-primary animate-up-2"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Row>
        </Container>
      </section> */}
      <section
        className="section section-lg line-bottom-soft"
        id="how-it-works"
      >
        <Container>
          <Row className="justify-content-center mb-5 mb-lg-6">
            <Col xs={12} className="text-center">
              <h2 className="px-lg-5">
                See how <span style={{ color: 'GrayText' }}>easy</span> it is to
                use our services
              </h2>
              {howItWorks.map((it) => (
                <Feature
                  key={`step-${it.id}`}
                  icon={it.icon}
                  title={it.title}
                  description={it.text}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-lg bg-primary" id="track-shipment">
        <Container>
          <Row className="justify-content-center text-center text-white mb-5">
            <p className="px-lg-8">Track Shipment</p>
            <div className="form-group has-success">
              <input
                type="text"
                placeholder="Tracking ID"
                className={`form-control ${
                  trackingID === 'TOBIT' ? 'is-valid' : ''
                }`}
                value={trackingID}
                onChange={handleIDChange}
              />
            </div>
            <Button>Submit</Button>
          </Row>
        </Container>
      </section>
      <section className="section section-lg bg-white" id="contact">
        <Container>
          <Row>
            <h2>Contact us</h2>
            <p className="mb-2">We're all ears</p>
            <p>Please choose a topic below related to your inquiry:</p>

            <ul>
              <li>
                <a href="https://tobitmisoi.com">Help &amp; Support</a>
              </li>
              <li>
                <span className="mb-2">
                  Email enquiries:{' '}
                  <a href="mailto:misoitobit@gmail.com">contact@totamica.com</a>
                </span>
              </li>
              <li>
                <span className="mb-2">Our address</span>
                <address>
                  Totamica Logistics <br /> P.O. Box 012, Eldoret <br /> 30100
                  Kenya
                </address>
              </li>
            </ul>
          </Row>
        </Container>
      </section>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={HashLink}
                to="#home"
                className="me-lg-3 mb-3 d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faFire} />
                <span className="ms-2 brand-text">Totamica Logistics</span>
              </Navbar.Brand>
              <p>
                Totamica logistics is a modern transport solution for clients
                across 9 countries
              </p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">tobitmisoi</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link target="_blank" href="https://tobitmisoi.com">
                    Blog
                  </Card.Link>
                </li>
                <li>
                  <Card.Link target="_blank" href="https://tobitmisoi.com">
                    Products
                  </Card.Link>
                </li>
                <li>
                  <Card.Link
                    target="_blank"
                    href="https://tobitmisoi.com/about"
                  >
                    About Us
                  </Card.Link>
                </li>
                <li>
                  <Card.Link
                    target="_blank"
                    href="https://tobitmisoi.com/contact"
                  >
                    Contact Us
                  </Card.Link>
                </li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Other</span>
              <ul className="links-vertical mt-2">
                <li>
                  <Card.Link as={Link} to={`/`} target="_blank">
                    Getting started
                  </Card.Link>
                </li>
                <li>
                  <Card.Link as={Link} to={`/`} target="_blank">
                    Changelog
                  </Card.Link>
                </li>
                <li>
                  <Card.Link target="_blank" href="https://tobitmisoi.com">
                    License
                  </Card.Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">Subscribe</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input
                      type="email"
                      className="form-control mb-2"
                      placeholder="example@company.com"
                      name="email"
                      aria-label="Subscribe form"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-secondary text-dark shadow-soft btn-block"
                      data-loading-text="Sending"
                    >
                      <span>Subscribe</span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">
                We’ll never share your details. See our{' '}
                <Card.Link className="text-white" href="#">
                  Privacy Policy
                </Card.Link>
              </p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link
                href="https://tobitmisoi.com"
                target="_blank"
                className="d-flex justify-content-center"
              >
                <FontAwesomeIcon icon={faFire} className="logo" />
              </Card.Link>
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © totamica 2020-
                  <span className="current-year">2021</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
