export const Routes = {
  // pages
  Presentation: { path: "" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Billing: { path: "/billing" },
  Invoice: { path: "/invoice" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  Lock: { path: "/lock" },
  NotFound: { path: "error/404" },
  ServerError: { path: "/error/500" },
  Profile: { path: "/profile" },
  Messages: { path: "/dashboard/messages" },
  Support: { path: "/dashboard/support" },
  TrackAndTrace: { path: "/dashboard/track-and-trace" },
  Calendar: { path: "/dashboard/calendar" },
  Map: { path: "/dashboard/map" },
};
