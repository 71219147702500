import React from "react";
import { Image } from "@themesberg/react-bootstrap";

import TotamicaImg from "../../assets/img/totamicalogo1.png";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <FontAwesomeIcon
        icon={faFire}
        className='loader-element animate__animated animate__jackInTheBox'
      />
    </div>
  );
};
