import { Card } from '@themesberg/react-bootstrap';
import React from 'react';

function Support() {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Support</Card.Title>
        <Card.Text>
          <p>
            If you have any questions or concerns, please contact us at{' '}
            <a href="mailto:misoitobit@gmail.com">support@totamica.com</a>
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Support;
