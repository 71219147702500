/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import moment from 'moment-timezone';
import {
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Image,
  Button,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faDownload,
  faRocket,
} from '@fortawesome/free-solid-svg-icons';
import BS5Logo from '../../assets/img/technologies/bootstrap-5-logo.svg';
import ReactLogo from '../../assets/img/technologies/react-logo.svg';
import LaravelLogo from '../../assets/img/technologies/laravel-logo.svg';
import GitHubButton from 'react-github-btn';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes/routes';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default (props: { toggleSettings?: any; showSettings?: any }) => {
  const currentYear = moment().get('year');

  const { showSettings } = props;

  const toggleSettings = (toggle: boolean) => {
    props.toggleSettings(toggle);
  };

  return (
    <div>
      {showSettings ? (
        <Card className="theme-settings">
          <Card.Body className="pt-4">
            <Button
              className="theme-settings-close"
              variant="close"
              size="sm"
              aria-label="Close"
              onClick={() => {
                toggleSettings(false);
              }}
            />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0 mb-1 me-3 fs-7">
                Made with open source{' '}
                <span role="img" aria-label="gratitude">
                  💙
                </span>
                <br />
                by <a href={'https://tobitmisoi.com'}>tobitmisoi</a>
              </p>
              <GitHubButton
                href="https://github.com/TobitMisoi/radio"
                data-size="large"
                data-show-count="true"
                aria-label="Star totamica"
              >
                Star
              </GitHubButton>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Card
          className="theme-settings theme-settings-expand"
          onClick={() => {
            toggleSettings(true);
          }}
        >
          <Card.Body className="p-3 py-2">
            <span className="fw-bold h6">
              <FontAwesomeIcon icon={faGithub} className="me-1 fs-7" /> Github
            </span>
          </Card.Body>
        </Card>
      )}
      <footer className="footer section py-5">
        <Row>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0">
            <p className="mb-0 text-center text-xl-left">
              Copyright © 2020-{`${currentYear} `}
              <Card.Link
                href="https://tobitmisoi.com"
                target="_blank"
                className="text-blue text-decoration-none fw-normal"
              >
                Totamica
              </Card.Link>
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://tobitmisoi.com/about" target="_blank">
                  About
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://tobitmisoi.com" target="_blank">
                  Shippers
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://tobitmisoi.com" target="_blank">
                  Blog
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link
                  href="https://tobitmisoi.com/contact"
                  target="_blank"
                >
                  Contact
                </Card.Link>
              </li>
            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};
