const howItWorks = [
  {
    id: "1",
    title: "Login",
    icon: "",
    text: "",
  },
  {
    id: "2",
    title: "Search for your suitable carrier",
    icon: "",
    text: "",
  },
  {
    id: "3",
    title: "Checkout",
    icon: "",
    text: "",
  },
];

export default howItWorks;
