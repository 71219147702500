import { Card } from '@themesberg/react-bootstrap';
import React from 'react';

function Messages() {
  return (
    <Card>
      <Card.Header>Messages</Card.Header>
      <Card.Body>
        <Card.Text>Messages here</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Messages;
