import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// import {} from
import { Routes as rtx } from '../routes/routes';
import RouteWithSidebar from './pages/configurations/route.with.sidebar';
import DashboardOverview from './pages/dashboard/dashboard.overview';
import Calendar from './pages/features/calendar';
import Map from './pages/features/map';
import TrackAndTrace from './pages/features/track.and.trace';
import Messages from './pages/messages';
import NotFound from './pages/others/not.found';
import Presentation from './pages/presentation';
import Settings from './pages/settings';
import Support from './pages/support';
import Transactions from './pages/transactions';

const App = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Presentation />,
    },
    {
      element: <RouteWithSidebar />,
      children: [
        { path: rtx.DashboardOverview.path, element: <DashboardOverview /> },
        { path: rtx.Transactions.path, element: <Transactions /> },
        { path: rtx.TrackAndTrace.path, element: <TrackAndTrace /> },
        { path: rtx.Calendar.path, element: <Calendar /> },
        { path: rtx.Map.path, element: <Map /> },
        { path: rtx.Messages.path, element: <Messages /> },
        { path: rtx.Support.path, element: <Support /> },
        { path: rtx.Settings.path, element: <Settings /> },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return routes;
};

export default App;
