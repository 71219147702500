import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";

export const GeneralInfoForm = () => {
  const [birthday, setBirthday] = useState("");

  return (
    <Card border='light' className='bg-white shadow-sm mb-4'>
      <Card.Body>
        <h5 className='mb-4'>General information</h5>
        <Form>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group id='firstName'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='Enter your first name'
                />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group id='lastName'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='Also your last name'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md={6} className='mb-3'>
              <Form.Group id='birthday'>
                <Form.Label>Birthday</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={setBirthday}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type='text'
                        value={
                          birthday ? moment(birthday).format("MM/DD/YYYY") : ""
                        }
                        placeholder='mm/dd/yyyy'
                        onFocus={openCalendar}
                        onChange={() => {
                          console.log("change");
                        }}
                      />
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group id='gender'>
                <Form.Label>Gender</Form.Label>
                <Form.Select defaultValue='0'>
                  <option value='0'>Gender</option>
                  <option value='1'>Female</option>
                  <option value='2'>Male</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group id='emal'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type='email'
                  placeholder='name@company.com'
                />
              </Form.Group>
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group id='phone'>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  required
                  type='number'
                  placeholder='+254 712345678'
                />
              </Form.Group>
            </Col>
          </Row>

          <h5 className='my-4'>Address</h5>
          <Row>
            <Col sm={9} className='mb-3'>
              <Form.Group id='address'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='Enter your home address'
                />
              </Form.Group>
            </Col>
            <Col sm={3} className='mb-3'>
              <Form.Group id='addressNumber'>
                <Form.Label>Number</Form.Label>
                <Form.Control required type='number' placeholder='No.' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className='mb-3'>
              <Form.Group id='city'>
                <Form.Label>City</Form.Label>
                <Form.Control required type='text' placeholder='City' />
              </Form.Group>
            </Col>
            <Col sm={4} className='mb-3'>
              <Form.Group className='mb-2'>
                <Form.Label>Select state</Form.Label>
                <Form.Select id='state' defaultValue='0'>
                  <option value='KE'>Kenya</option>
                  <option value='UG'>Uganda</option>
                  <option value='TZ'>Tanzania</option>
                  <option value='SO'>Somalia</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id='zip'>
                <Form.Label>ZIP</Form.Label>
                <Form.Control required type='tel' placeholder='ZIP' />
              </Form.Group>
            </Col>
          </Row>
          <div className='mt-3'>
            <Button variant='primary' type='submit'>
              Save All
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
