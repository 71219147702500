import React from 'react';
import ReactDOM from 'react-dom/client';

// core styles
import './app/scss/totam.scss';

// vendor styles
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datetime/css/react-datetime.css';

// import HomePage from './app/pages/home.page';
// import ScrollToTop from './app/components/ScrollToTop';
import App from './app/app';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
